import { Button } from "../Button";
import { CMSComponentExampleProps } from "../cms/CMSComponentExampleProps";
import {
  CMSImageResource,
  CMSPageIdResource,
  CMSSchedule,
  CMSText,
  InjectableComponentProps,
} from "../cms/types/cmsTypes";
import {
  getImageUrl,
  getRawSchedule,
  getRawText,
} from "../../utils/cms/renderableResources";
import moment from "moment-timezone";
import { placeholderImageUrlWithText } from "stories/helpers";
import { ReactEventHandler, useEffect, useState } from "react";
import calculateSchedule from "../../utils/schedule/calculateSchedule";
import { exampleRecurringSchedule } from "../../test/utils/recurringSchedule";
import { useTracking } from "../trackEvent";
import { DateWithType } from "../../utils/schedule/calculateRecurringSchedule";
import { usePageEventListener } from "../cms/PageContext";
import Requires from "../../internal_components/Requires";
import { CurrentEnvironment } from "../../utils/CurrentEnvironment";
import { isValidEmail } from "@/utils/isValidEmail";
import { useFeatureFlag } from "components/useFeatureFlag";
import { Modal } from "./Modal";
import WebinarNameInput from "./WebinarNameInput";
import WebinarEmailInput from "./WebinarEmailInput";
import WebinarTimeAndTimezoneInput from "./WebinarTimeAndTimezoneInput.tsx";
import WebinarRegistrationSubmitButton from "./WebinarRegistrationSubmitButton";
import WebinarFormSubmitError from "./WebinarFormSubmitError";
import WebinarRegistrationFormHeader from "./WebinarRegistrationFormHeader";

export const noOptionsWorkForMeKey = "none";

export type WebinarRegistrationFormState = {
  name: string;
  email: string;
  webinarBrandPersonaImage: string;
  webinarBrandPersonaName: string;
  webinarPageId: string;
  startTime: {
    datetime: string;
    type: string;
  };
  day: {
    datetime: string;
    type: string;
  };
  timeZone: string;
  brandId: string;
  buttonText: string;
};

export type WebinarRegistrationFormError = {
  email: boolean;
  name: boolean;
};

const CCWebinarRegistrationModal1 = ({
  useNewRegistration,
  webinarId,
  brand,
  buttonText,
  webinarBrandPersonaImage,
  webinarBrandPersonaName,
  webinarPageId,
  schedule,
  clientSideId,
  redirectToUrl,
  registrationHeadline,
  hideModal,
  injected = false,
  handleRedirect,
  featureFlags,
  featureFlagHash,
}: InjectableComponentProps<CCWebinarRegistration1Props> & {
  hideModal?: () => void;
  injected?: boolean;
  handleRedirect?: ({ url }: { url: string }) => void;
}) => {
  const { track } = useTracking(injected);

  const [showModal, setShowModal] = useState(injected);
  const [formError, setFormError] = useState<WebinarRegistrationFormError>({
    email: false,
    name: false,
  });
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [step, setStep] = useState(1);
  const [webinarTimeOptions, setWebinarTimeOptions] = useState<DateWithType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookies] = useState({});
  const [userAgent, setUserAgent] = useState("");
  const [transactionUrl, setTransactionUrl] = useState("");
  const [noneOptionsMessage, setNoneOptionsMessage] = useState(false);
  const modalType = useFeatureFlag("webinarRegistrationModal", {
    fallback: "originalWebinarRegistration",
  });
  const availabilityType = useFeatureFlag("webinarAvailabilityOptions", {
    fallback: "original",
  });

  const [dripTag, setDripTag] = useState("");
  const [formState, setFormState] = useState<WebinarRegistrationFormState>({
    name: "",
    email: "",
    webinarBrandPersonaImage: getImageUrl(webinarBrandPersonaImage),
    webinarBrandPersonaName: getRawText(webinarBrandPersonaName),
    webinarPageId: getRawText(webinarPageId),
    startTime: { datetime: "", type: "" },
    day: { datetime: "", type: "" },
    timeZone: moment.tz.guess(),
    brandId: brand.id,
    buttonText: getRawText(buttonText),
  });

  useEffect(() => {
    const root = document.documentElement;

    window.focus();
    root.addEventListener("keydown", function (event) {
      if (event.key === "Escape" && hideModal) {
        hideModal();
      }
    });
  }, [hideModal]);

  useEffect(() => {
    if (showModal) {
      window.parent.postMessage(
        JSON.stringify({
          type: "open-modal",
          payload: {
            featureFlags,
            clientSideDecisionId: featureFlagHash,
          },
        }),
        "*"
      );
    }
  }, [featureFlagHash, featureFlags, showModal]);

  const handleMessage = ({
    data,
  }: MessageEvent<{ type: string; payload: string }>) => {
    try {
      if (data.type === "cc-analytics-data") {
        const payload = JSON.parse(data.payload);
        setCookies(payload.cookies);
        setUserAgent(payload.userAgent);
        setTransactionUrl(payload.transactionUrl);
      } else if (data.type === "cc-drip-tag") {
        setDripTag(data.payload);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  useEffect(
    () => {
      let options: DateWithType[] | null = [];
      const rawSchedule = getRawSchedule(schedule);
      if (rawSchedule) {
        options = calculateSchedule(
          new Date(),
          rawSchedule,
          formState.timeZone
        );
      }

      setWebinarTimeOptions(options || []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schedule, setWebinarTimeOptions]
  );

  usePageEventListener("showModal", () => {
    setShowModal(true);
  });

  useEffect(() => {
    let options: DateWithType[] | null = [];
    const rawSchedule = getRawSchedule(schedule);
    if (rawSchedule) {
      options = calculateSchedule(new Date(), rawSchedule, formState.timeZone);
    }

    setWebinarTimeOptions(options || []);
  }, [schedule, setWebinarTimeOptions, formState.timeZone]);

  function createRequestBody() {
    const formStateDup = { ...formState };
    formStateDup.email = formState.email.toLowerCase();

    if (
      formStateDup.email.endsWith("@example.com") ||
      formStateDup.email.endsWith("@combinedcuriosity.com")
    ) {
      // Add a param to the current URL
      const url = new URL(window.location.toString());
      url.searchParams.set("test", "true");
      window.history.pushState(null, "", url.toString());
    }

    const currentEnv = CurrentEnvironment();
    let base: string;
    if (currentEnv === "prd") {
      base = `https://cc-api.${brand.domain}`;
    } else if (currentEnv === "stg") {
      base = "https://stg-xms-api.combinedcuriosity.io";
    } else {
      base = "http://localhost:3001";
    }

    const body = {
      ...formStateDup,
      times: webinarTimeOptions.map((option) => {
        return {
          datetime: option.toUTCString(),
          type: option.type,
        };
      }),
      transactionUrl: window.document.URL,
      clientSideId,
      redirectToUrl: getRawText(redirectToUrl),
      startTime: formStateDup.startTime.datetime,
      scheduleType: formStateDup.startTime.type,
      webinarId: webinarId,
      clientSideDecisionId: featureFlagHash,
      dripTag: dripTag,
      availableValues: { webinarTimeOptions },
      injected: {
        cookies,
        userAgent,
        transactionUrl,
      },
    };

    return { body, base };
  }

  const handleChange: ReactEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (e) => {
    const t = e.target;
    if (t instanceof HTMLInputElement) {
      if (t.type === "radio") {
        setFormState({ ...formState, [t.name]: JSON.parse(t.value) });
      } else {
        setFormState({ ...formState, [t.name]: t.value });
      }
    } else if (
      t instanceof HTMLInputElement ||
      t instanceof HTMLSelectElement
    ) {
      // time and day are stored as JSON strings
      try {
        if (t.value === noOptionsWorkForMeKey) {
          // Handle the case where "None of These Options Work For Me" is selected
          setFormState({
            ...formState,
            [t.name]: {
              datetime: "",
              type: noOptionsWorkForMeKey,
            },
          });
        } else {
          setFormState({ ...formState, [t.name]: JSON.parse(t.value) });
        }
      } catch (error) {
        console.log("error", error);
        setFormState({ ...formState, [t.name]: t.value });
      }
    }
  };

  const handleNext = async () => {
    try {
      if (step === 1) {
        if (!isValidEmail(formState.email)) {
          // Show an error message or handle the invalid email case
          console.error("Invalid email address. Please provide a valid email.");
          return;
        }

        setStep(2);

        const { base, body } = createRequestBody();
        const formStateDup = { ...formState };
        const dripTags =
          formStateDup.startTime.type === noOptionsWorkForMeKey
            ? ["webinar_registration_intent", "registered_none_of_the_times"]
            : ["webinar_registration_intent"];
        const dripExtra = {
          tags: dripTags,
        };

        const nextStepResponse = await fetch(
          base + "/api/leads/createOrUpdateLead/",
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...body,
              cookies,
              dripTags: ["webinar_registration_intent"],
              phone: "",
              type: "webinar_registration_intent",
              action: "lead_submission",
              metadata: {
                transaction_url: transactionUrl,
                availableValues: body?.times,
                values: [
                  {
                    name: body?.name,
                    email: body?.email,
                  },
                ],
                formName: "webinar_registration",
                user_agent: "unknown",
                drip_extra: dripExtra,
              },
              brand_id: brand?.id,
            }),
          }
        );
        if (nextStepResponse.ok) {
          const { lead } = await nextStepResponse.json();
          track(
            {
              leadId: lead.id,
              action: "lead_submission",
              email: formStateDup.email.toLowerCase(),
              cookies,
              meta: {
                values: {
                  name: formStateDup.name,
                  email: formStateDup.email.toLowerCase(),
                },
                type: "webinar_registration_intent",
                formName: "webinar_registration",
                availableValues: {},
              },
            },
            "beacon"
          );
        } else {
          return formSubmitError;
        }
      }
    } catch (e: any) {
      console.log(e?.message);
    }
  };

  const createOrUpdateLead = async ({
    urlBase,
    formStateDup,
    body,
  }: {
    urlBase: string;
    formStateDup: WebinarRegistrationFormState;
    body: ReturnType<typeof createRequestBody>["body"];
  }) => {
    try {
      const dripTags =
        formStateDup.startTime.type === noOptionsWorkForMeKey
          ? ["webinar_registration_intent", "registered_none_of_the_times"]
          : ["webinar_registration_intent"];
      const createLeadResponse = await fetch(
        urlBase + "/api/leads/createOrUpdateLead/",
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...body,
            cookies,
            phone: "",
            type: "webinar_registration_intent",
            dripTags,
            metadata: {
              transaction_url: transactionUrl,
              availableValues: body?.times,
              values: [
                {
                  name: body?.name,
                  email: body?.email,
                },
              ],
              formName: "webinar_registration",
              user_agent: "unknown",
            },
            brand_id: brand?.id,
          }),
        }
      );
      if (createLeadResponse.ok) {
        const { lead } = await createLeadResponse.json();
        const times = webinarTimeOptions.map((option) => {
          return {
            datetime: option.toUTCString(),
            type: option.type,
          };
        });
        track(
          {
            leadId: lead.id,
            action: "form_submission",
            email: formStateDup.email.toLowerCase(),
            clientSubmissionUniqueId: clientSideId,
            clientSideDecisionId: featureFlagHash,
            cookies,
            featureFlags: featureFlags,
            meta: {
              values: {
                email: formStateDup.email.toLowerCase(),
                name: formStateDup.name,
              },
              formName: "webinar_registration",
              availableValues: {
                times: times,
              },
              type: "webinar_registration_intent",
            },
          },
          "beacon"
        );
      } else {
        return formSubmitError;
      }
    } catch (e: any) {
      console.log(e?.message);
    }
    setFormSubmitError(false);
  };

  const submitRegistration = async ({
    urlBase,
    body,
  }: {
    urlBase: string;
    body: ReturnType<typeof createRequestBody>["body"];
  }) => {
    let url;

    if (useNewRegistration) {
      url = urlBase + "/api/webinar/register-viper/";
    } else {
      url = urlBase + "/api/webinar/register/";
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const {
        success,
        leadId,
        webinarInstanceId,
        webinarId,
        webinarRegistrationId,
        redirectUrl,
      } = await response.json();

      if (success) {
        track(
          {
            email: formState.email.toLowerCase(),
            leadId: leadId,
            action: "webinar_registration",
            clientSubmissionUniqueId: clientSideId,
            clientSideDecisionId: featureFlagHash,
            featureFlags: featureFlags,
            cookies,
            meta: {
              name: formState.name,
              scheduledDatetime: new Date(formState.startTime.datetime),
              timezone: formState.timeZone,
              webinarId: webinarId,
              webinarRegistrationId: webinarRegistrationId,
              webinarInstanceId: webinarInstanceId,

              scheduleType:
                formState.startTime.type === "JIT"
                  ? "just_in_time"
                  : "scheduled",
            },
          },
          "beacon"
        );

        if (typeof window !== "undefined") {
          if (handleRedirect) {
            handleRedirect({ url: redirectUrl });
          } else {
            window.location = redirectUrl;
          }
        }
      }
    } else {
      setIsLoading(false);
      setFormSubmitError(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { body, base } = createRequestBody();
    const formStateDup = { ...formState };

    if (formStateDup.startTime.type === noOptionsWorkForMeKey) {
      // Display a message for the "None of These Options Work For Me" case
      setNoneOptionsMessage(true);
      await createOrUpdateLead({
        urlBase: base,
        formStateDup: formStateDup,
        body,
      });
      return;
    }

    setFormSubmitError(false);
    setNoneOptionsMessage(false);
    setIsLoading(true);

    await submitRegistration({ urlBase: base, body });
  };

  const submitIsDisabled = () => {
    if (!isValidEmail(formState.email) || formError.name) {
      return true;
    }

    // noOptions doesn't require timezone or type
    if (formState.startTime.type === noOptionsWorkForMeKey) {
      return false; // Enable submission for the "None of These Options Work For Me" case
    }

    // regular timezone _does_ require timezone and type
    return !formState.timeZone || !formState.startTime.type;
  };

  const handleModalClose = () => {
    track({
      action: "page_interaction",
      meta: {
        interaction: "modal_close",
        resourceType: "webinar_registration",
      },
    });

    if (injected) {
      hideModal && hideModal();
    } else {
      setShowModal(false);
      setFormSubmitError(false);
    }
  };

  const renderOriginalWebinarRegistration = () => {
    return (
      <Modal isOpen={showModal} onClose={handleModalClose}>
        <div className="max-h-screen overflow-y-auto">
          <WebinarRegistrationFormHeader
            image={webinarBrandPersonaImage}
            brand={brand}
            webinarBrandPersonaName={getRawText(webinarBrandPersonaName)}
          />

          <Requires value={!formSubmitError}>
            <form
              className="bg-white shadow-md rounded rounded-t-none p-8 pt-4 gap-4 flex flex-col"
              onSubmit={handleSubmit}
            >
              <p className="text-center text-xl my-2 font-bold">
                {getRawText(registrationHeadline)}
              </p>
              <WebinarNameInput
                formState={formState}
                setFormError={setFormError}
                handleChange={handleChange}
                formError={formError}
              />
              <WebinarEmailInput
                formState={formState}
                setFormError={setFormError}
                handleChange={handleChange}
                formError={formError}
                clientSideId={clientSideId}
                featureFlagHash={featureFlagHash}
                featureFlags={featureFlags}
                track={track}
                setFormState={setFormState}
              />
              {formError.email && (
                <p className="text-red-500 text-sm mt-2">
                  Invalid email address. Please provide a valid email.
                </p>
              )}

              <WebinarTimeAndTimezoneInput
                formState={formState}
                handleChange={handleChange}
                availabilityType={availabilityType}
                webinarTimeOptions={webinarTimeOptions}
              />

              <Requires value={!noneOptionsMessage}>
                <div className="flex justify-center mt-6">
                  <WebinarRegistrationSubmitButton
                    isLoading={isLoading}
                    submitIsDisabled={submitIsDisabled}
                    buttonText={getRawText(buttonText)}
                  />
                </div>
              </Requires>
            </form>
          </Requires>

          <WebinarFormSubmitError
            isLoading={isLoading}
            formSubmitError={formSubmitError}
            submitIsDisabled={submitIsDisabled}
            brand={brand}
            formState={formState}
          />

          <Requires value={noneOptionsMessage}>
            <div className="bg-white shadow-md rounded rounded-t-none p-8 pt-4 gap-4 flex flex-col">
              <div className="text-center text-lg">
                Thank you for your interest! Be on the lookout for future
                webinar times in your email.
              </div>
            </div>
          </Requires>
        </div>
      </Modal>
    );
  };

  const renderMultiStepWebinarRegistration = () => {
    return (
      <Modal isOpen={showModal} onClose={handleModalClose}>
        <WebinarRegistrationFormHeader
          image={webinarBrandPersonaImage}
          brand={brand}
          webinarBrandPersonaName={getRawText(webinarBrandPersonaName)}
        />

        <form
          className="bg-white shadow-md rounded rounded-t-none p-8 pt-4 gap-4 flex flex-col"
          onSubmit={handleSubmit}
        >
          <div className="max-h-screen overflow-y-auto">
            {step === 1 && (
              <div>
                <p className="text-center text-xl my-2 font-bold">
                  Register for Webinar
                </p>
                <WebinarNameInput
                  formState={formState}
                  setFormError={setFormError}
                  handleChange={handleChange}
                  formError={formError}
                />
                <WebinarEmailInput
                  formState={formState}
                  setFormError={setFormError}
                  handleChange={handleChange}
                  formError={formError}
                  clientSideId={clientSideId}
                  featureFlagHash={featureFlagHash}
                  featureFlags={featureFlags}
                  track={track}
                  setFormState={setFormState}
                />
                {formError.email && (
                  <p className="text-red-500 text-sm mt-2">
                    Invalid email address. Please provide a valid email.
                  </p>
                )}

                <div className="flex justify-center mt-6">
                  <Button
                    onClick={handleNext}
                    className="bg-tertiary hover:bg-tertiary-darker text-white font-bold py-4 px-8 rounded focus:outline-none focus:shadow-outline"
                    type="button" // Use type="button for the "Next" button
                    disabled={false}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div>
                <p className="text-center text-xl my-2 font-bold">
                  Register For Webinar
                </p>
                <WebinarTimeAndTimezoneInput
                  formState={formState}
                  handleChange={handleChange}
                  availabilityType={availabilityType}
                  webinarTimeOptions={webinarTimeOptions}
                />
                <Requires value={!noneOptionsMessage}>
                  <div className="flex justify-center mt-6">
                    <WebinarRegistrationSubmitButton
                      isLoading={isLoading}
                      submitIsDisabled={submitIsDisabled}
                      buttonText={getRawText(buttonText)}
                    />
                  </div>
                </Requires>
              </div>
            )}
          </div>
        </form>

        <WebinarFormSubmitError
          isLoading={isLoading}
          formSubmitError={formSubmitError}
          submitIsDisabled={submitIsDisabled}
          brand={brand}
          formState={formState}
        />
        <Requires value={noneOptionsMessage}>
          <div className="bg-white shadow-md rounded rounded-t-none p-8 pt-4 gap-4 flex flex-col">
            <div className="text-center text-lg">
              Thank you for your interest! Be on the lookout for future webinar
              times in your email.
            </div>
          </div>
        </Requires>
      </Modal>
    );
  };

  switch (modalType) {
    case "originalWebinarRegistration":
      return renderOriginalWebinarRegistration();
    case "multiStepWebinarRegistration":
      return renderMultiStepWebinarRegistration();
    default:
      // should never happen, but let's be safe
      return renderOriginalWebinarRegistration();
  }
};

type CCWebinarRegistration1Props = {
  useNewRegistration?: boolean;
  webinarId?: string;
  buttonText: CMSText;
  webinarBrandPersonaImage: CMSImageResource;
  webinarBrandPersonaName: CMSText;
  webinarPageId: CMSPageIdResource;
  schedule: CMSSchedule;
  redirectToUrl: CMSText;
  registrationHeadline: CMSText;
};

export const CCWebinarRegistrationModal1StorybookProps: CMSComponentExampleProps<CCWebinarRegistration1Props> =
  {
    buttonText: {
      type: "text",
      value: "Sign me up!",
      defaultTags: [{ type: "button_text" }],
    },
    webinarBrandPersonaImage: {
      type: "image_resource",
      value: placeholderImageUrlWithText(
        200,
        200,
        "Webinar Brand Persona Image"
      ),
      defaultTags: [{ type: "webinar_brand_persona_image" }],
    },
    webinarBrandPersonaName: {
      type: "text",
      value: "Brand Persona Name",
      defaultTags: [{ type: "webinar_brand_persona_name" }],
    },
    webinarPageId: {
      type: "text",
      value: "1234",
      defaultTags: [{ type: "webinar_page_id" }],
    },
    schedule: {
      type: "schedule",
      value: exampleRecurringSchedule,
    },
    redirectToUrl: {
      type: "text",
      value: "https://www.google.com",
      defaultTags: [{ type: "webinar_thank_you_page_url" }],
    },
  };

export default CCWebinarRegistrationModal1;
